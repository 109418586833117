import { Form } from "react-bootstrap";
import FormLabel from "../../../components/Text/FormLabel";
import ReactInput from "../../../components/InputList/ReactInput/ReactInput";
import FormLayout from "../../../components/Layout/FormLayout";
import { useFormikContext } from "formik";

const SliderFormContainer = ({ editData, submitHandler }) => {
  const initialValues = {
    title: editData?.title || "",
    description: editData?.description || "",
    image: editData?.image || "",
    status: editData ? editData?.status : true,
  };
  const apiHandler = (data) => {
    const formData = new FormData();
    Object.keys(data).forEach((key) => {
      formData.append(key, data[key]);
    });
    if (submitHandler) {
      return submitHandler(formData);
    }
    return null;
  };
  return (
    <FormLayout initialValues={initialValues} apiHandler={apiHandler}>
      <SliderForm />
    </FormLayout>
  );
};

export default SliderFormContainer;
const SliderForm = () => {
  const { values, setFieldValue } = useFormikContext();
  return (
    <div>
      <Form.Group className="mb-3">
        <FormLabel title="Title" required={true} />
        <ReactInput name="title" required={true} />
      </Form.Group>

      <Form.Group className="mb-3">
        <FormLabel title="Image" />
        <ReactInput name="image" type="file" />
      </Form.Group>

      <Form.Group className="mb-3">
        <FormLabel title="Description" />
        <ReactInput name="description" as="textarea" rows={3} />
      </Form.Group>

      <Form.Group className="mb-3">
        <Form.Check
          type="checkbox"
          label="Status"
          name="status"
          checked={values?.status}
          onChange={(e) => setFieldValue("status", e.target.checked)}
        />
      </Form.Group>
    </div>
  );
};
