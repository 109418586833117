import { useState } from "react";
import DeleteButton from "../../../components/Button/DeleteButton";
import EditButton from "../../../components/Button/EditButton";
import CardLayout from "../../../components/Layout/CardLayout";
import LoaderLayout from "../../../components/Loader/LoaderLayout";
import useFetch from "../../../hooks/useFetch";
import request from "../../../request/request";
import ReactTable from "./../../../components/ReactDataTable/ReactTable";
import ModalLayout from "../../../components/Layout/ModalLayout";
import FormLayout from "../../../components/Layout/FormLayout";
import SocialMediaForm from "./SocialMediaForm";
import DeleteModal from "../../../components/Modal/DeleteModal";
import { format } from "date-fns";

const SocialMediaLinkList = () => {
  const { result, isLoading, setRefresh } = useFetch(() =>
    request.getAll("socialMedia")
  );

  console.log(result);

  // state for add and edit modal
  const [modal, setModal] = useState({ type: "", selectedId: "" });

  const columns = [
    {
      key: "name",
      text: "Name",
    },
    {
      text: "Icon",
      key: "icon",
    },
    {
      text: "Link",
      key: "link",
    },
    {
      text: "Status",
      key: "status",
      cell: (row) => (row.status === 1 ? "Active" : "Inactive"),
    },
    {
      text: "Live Start",
      key: "live_start",
      cell: (row) =>
        row.live_start
          ? format(new Date(row.live_start), "dd MMM yyyy @p")
          : "",
    },
    {
      text: "Live End",
      key: "live_end",
      cell: (row) =>
        row.live_end ? format(new Date(row.live_end), "dd MMM yyyy @p") : "",
    },
    {
      text: "Action",
      key: "action",
      width: "150px",
      cell: (row) => (
        <div>
          <EditButton
            onClick={() => setModal({ type: "edit", selectedId: row?.id })}
          />
          <DeleteButton
            onClick={() => setModal({ type: "delete", selectedId: row?.id })}
          />
        </div>
      ),
    },
  ];

  // modal close function
  const closeModal = () => {
    setModal({ type: "", selectedId: "" });
  };

  const selectData = result?.find((data) => data.id === modal.selectedId);

  return (
    <LoaderLayout isPending={isLoading}>
      <CardLayout
        title={"Social Media Link List"}
        buttonTitle={"Add New"}
        addButtonHandler={() => setModal({ type: "add", selectedId: "" })}
      >
        <ReactTable columns={columns} data={result} isLoading={isLoading} />
      </CardLayout>

      {/* create form     */}
      {modal.type === "add" && (
        <ModalLayout
          title={"Add New Social"}
          isOpen={modal.type === "add"}
          onClose={closeModal}
        >
          <FormLayout
            initialValues={{
              name: "",
              icon: "",
              link: "",
              status: 1,
              live_start: "",
              live_end: "",
            }}
            apiHandler={async (e) => {
              return request.create("socialMedia", e, () => {
                closeModal();
                setRefresh((prev) => !prev);
              });
            }}
          >
            <SocialMediaForm />
          </FormLayout>
        </ModalLayout>
      )}

      {/* edit form */}

      {modal.type === "edit" && (
        <ModalLayout
          title={"Update New Social"}
          isOpen={modal.type === "edit"}
          onClose={closeModal}
        >
          <FormLayout
            initialValues={{
              name: selectData?.name || "",
              icon: selectData?.icon || "",
              link: selectData?.link || "",
              status: selectData?.status === 0 ? 0 : 1,
              live_start: selectData?.live_start || "",
              live_end: selectData?.live_end || "",
            }}
            apiHandler={async (e) => {
              console.log(e);
              return request.updateOne(
                "socialMedia",
                modal.selectedId,
                e,
                () => {
                  closeModal();
                  setRefresh((prev) => !prev);
                }
              );
            }}
          >
            <SocialMediaForm />
          </FormLayout>
        </ModalLayout>
      )}

      {/* delete form */}
      {modal.type === "delete" && (
        <DeleteModal
          isOpen={modal.type === "delete"}
          onClose={closeModal}
          handleDelete={async () => {
            return request.delete("socialMedia", modal.selectedId, () => {
              closeModal();
              setRefresh((prev) => !prev);
            });
          }}
        />
      )}
    </LoaderLayout>
  );
};
export default SocialMediaLinkList;
