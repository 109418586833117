import axios from "axios";
import errorHandler from "./errorHandler";
import successHandler from "./successHandler";

export const baseUrl = `${process.env.REACT_APP_API_BASE_URL}/`;

const request = {
  create: async (link, data, postFunc, headers) => {
    try {
      const response = await axios.post(baseUrl + link, data);

      successHandler(
        response,
        {
          notifyOnSuccess: true,
          notifyOnFailure: true,
        },
        postFunc
      );
      return response.data;
    } catch (error) {
      errorHandler(error);
      return error;
    }
  },
  getAll: async (link) => {
    try {
      const response = await axios.get(baseUrl + link);

      successHandler(response, {
        notifyOnSuccess: false,
        notifyOnFailure: false,
      });
      return response.data;
    } catch (error) {
      errorHandler(error);
      return error;
    }
  },
  getAllById: async (link, id) => {
    try {
      const response = await axios.get(`${baseUrl + link}/${id}`);

      successHandler(response, {
        notifyOnSuccess: false,
        notifyOnFailure: false,
      });
      return response.data;
    } catch (error) {
      errorHandler(error);
      return error;
    }
  },
  getOne: async (link, id) => {
    try {
      const response = await axios.get(`${baseUrl + link}/${id}`);
      successHandler(response, {
        notifyOnSuccess: false,
        notifyOnFailed: false,
      });
      return response.data;
    } catch (error) {
      errorHandler(error);
      return error;
    }
  },

  updateOne: async (link, id, data, postFunc) => {
    try {
      const response = await axios.put(`${baseUrl + link}/${id}`, data);
      successHandler(
        response,
        {
          notifyOnSuccess: true,
          notifyOnFailed: true,
        },
        postFunc
      );
      return response.data;
    } catch (error) {
      errorHandler(error);
      return error;
    }
  },

  delete: async (link, id, postFunc) => {
    try {
      const response = await axios.delete(`${baseUrl + link}/${id}`);
      console.log(response);
      successHandler(
        response,
        {
          notifyOnSuccess: true,
          notifyOnFailed: true,
        },
        postFunc
      );
      return response.data;
    } catch (error) {
      errorHandler(error);
      return error;
    }
  },
  imageUpload: async (link, data, postFunc) => {
    try {
      const response = await axios.post(baseUrl + link, data);

      successHandler(
        response,
        {
          notifyOnSuccess: false,
          notifyOnFailure: false,
        },
        postFunc
      );
      return response.data;
    } catch (error) {
      errorHandler(error);
      return error;
    }
  },
  filter: async (link, data, postFunc) => {
    try {
      const response = await axios.post(baseUrl + link, data);

      successHandler(
        response,
        {
          notifyOnSuccess: false,
          notifyOnFailure: false,
        },
        postFunc
      );
      return response.data;
    } catch (error) {
      errorHandler(error);
      return error;
    }
  },
};

export default request;
