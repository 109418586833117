import ReactDatatable from "@ashvin27/react-datatable";

import axios from "axios";
import React, { useContext, useState } from "react";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import PasswordChangeModal from "../../../components/Modal/PasswordChangeModal/PasswordChangeModal";
import { UserContext } from "../../../Context/UserContextAPI";
import { getBloodGroup } from "../../../utils/getBloodGroup";
import { Form } from "react-bootstrap";
import { FaEdit, FaEye, FaKey, FaPlus, FaTrash } from "react-icons/fa";
import ModalLayout from "../../../components/Layout/ModalLayout";
import UsersFormContainer from "./UsersFormContainer";

const jwtToken = localStorage.getItem("jwtToken");

const UserTable = ({
  users,
  refresh,
  setRefresh,
  showRole,
  role_id,
  searchHandler,
}) => {
  //   const [users, setUsers] = useState([]);
  //   const [roles, setRoles] = useState([]);
  const [editModalData, setEditModalData] = useState({});
  const [deleteModalData, setDeleteModalData] = useState({});
  //   const [refresh, setRefresh] = useState(false);
  const [isChecked, setIsChecked] = useState(true);
  const [passwordModalData, setPasswordModalData] = useState({});
  const { allRoles: roles, accessPerm } = useContext(UserContext);
  // console.log(roles);
  const [modalState, setModalState] = useState({
    modalType: "",
    modalData: {},
  });

  const navigate = useNavigate();

  // console.log(isChecked);

  const handlerOnDelete = async () => {
    const res = await axios.delete(
      `${process.env.REACT_APP_API_BASE_URL}/users/user/${deleteModalData?.id}`
    );
    // console.log(res);
    if (res.status === 200) {
      toast.success("User Deleted successfully!!");
      setRefresh(!refresh);
    }
  };

  const firstThreeColumn = [
    {
      key: "image",
      text: "Image",
      className: "image",
      align: "left",
      sortable: true,
      cell: (record) => {
        return (
          <>
            {record?.image ? (
              <img
                className=" img-fluid"
                style={{ width: "50px" }}
                src={`${process.env.REACT_APP_UPLOAD_URL}/users/${record?.image}`}
                // src={`${process.env.REACT_APP_UPLOAD_URL}/users/${record?.image}`}
                alt="Profile Pic"
              />
            ) : (
              <img
                className=" img-fluid"
                style={{ width: "50px" }}
                src="./dummy.jpg"
                alt="Profile Pic"
              />
            )}
          </>
        );
      },
    },
    {
      key: "registration_no",
      text: "Registration No",
      className: "registration_no",
      align: "left",
      sortable: true,
      cell: (record) => {
        return <>{record?.registration_no}</>;
      },
    },
    {
      key: "name",
      text: "Name / Company",
      className: "info",
      align: "left",
      sortable: true,
      cell: (record) => {
        return (
          <>
            {record?.f_name} {record?.l_name}
          </>
        );
      },
    },
  ];
  // console.log(users);

  const roleColumn = [
    {
      key: "role_id",
      text: "Role",
      className: "status",
      sortable: true,
      cell: (record) => {
        return <>{record?.role?.name}</>;
      },
    },
  ];

  const middleColumn = [
    {
      key: "mobile",
      text: "Mobile",
      className: "mobile",
      sortable: true,
    },
    {
      key: "email",
      text: "Email",
      className: "email",
      sortable: true,
      cell: (record) => {
        return <>{record?.email}</>;
      },
    },
    {
      key: "	date_of_birth",
      text: "Date of Birth",
      className: "	date_of_birth",
      sortable: true,
      cell: (record) => {
        return <>{record?.date_of_birth}</>;
      },
    },
    {
      key: "contact",
      text: "Contact Person",
      className: "contactPerson",
      sortable: true,
      cell: (record) => {
        return <>{record?.contact_person}</>;
      },
    },
    {
      key: "nid",
      text: "NID Number",
      className: "nidNumber",
      sortable: true,
      cell: (record) => {
        return <>{record?.nid}</>;
      },
    },
    // {
    //     key: "credit_limit",
    //     text: "Credit Limit",
    //     className: "credit_limit",
    //     sortable: true,
    //     cell: (record) => {
    //         return <>BDT {record?.credit_limit}</>;
    //     },
    // },
    // {
    //     key: "commission_rate",
    //     text: "Commission Rate",
    //     className: "commission_rate",
    //     sortable: true,
    //     cell: (record) => {
    //         return <>{record?.commission_rate}%</>;
    //     },
    // },
    {
      key: "wallet",
      text: "Wallet",
      className: "wallet",
      sortable: true,
      cell: (record) => {
        return <>{record?.wallet}</>;
      },
    },
  ];

  const lastColumnData = [
    {
      key: "status",
      text: "Status",
      className: "status",
      sortable: true,
      cell: (record) => {
        return <>{record?.status === 1 ? "Active" : "Inactive"}</>;
      },
    },
    {
      key: "action",
      text: "Action",
      className: "action",
      width: 100,
      align: "left",
      sortable: false,
      cell: (record) => {
        const filterRoles = roles?.filter((filterRole) => {
          return filterRole?.id !== record?.role_id;
        });

        // console.log(filterRoles);
        return (
          <>
            {/* View Profile Trigger Button */}
            {accessPerm(5, 5) && (
              <button
                type="button"
                onClick={() => navigate(`/dashboard/profile/${record?.id}`)}
                className="btn btn-warning btn-sm"
                style={{ marginRight: "5px" }}
              >
                <FaEye />
              </button>
            )}

            {/* Change Password Trigger Button */}
            {accessPerm(5, 6) && (
              <button
                type="button"
                onClick={() => setPasswordModalData(record)}
                className="btn btn-dark btn-sm"
                data-bs-toggle="modal"
                data-bs-target={`#changePasswordModal`}
                style={{ marginRight: "5px" }}
              >
                <FaKey />
              </button>
            )}

            {/* Edit User Trigger Button */}
            {accessPerm(5, 2) && (
              <button
                type="button"
                // onClick={() => {
                //   setEditModalData(record);
                //   if (record.status === 1) {
                //     setIsChecked(true);
                //   } else {
                //     setIsChecked(false);
                //   }
                // }}

                onClick={() =>
                  setModalState({
                    modalType: "edit",
                    modalData: record,
                  })
                }
                className="btn btn-primary btn-sm"
                // data-bs-toggle="modal"
                // data-bs-target={`#editUserModal-${record.id}`}
                style={{ marginRight: "5px" }}
              >
                <FaEdit />
              </button>
            )}

            {/* Delete User Trigger Button */}
            {accessPerm(5, 3) && (
              <button
                type="button"
                onClick={() => setDeleteModalData(record)}
                className="btn btn-danger btn-sm"
                data-bs-toggle="modal"
                data-bs-target={`#deleteUserModal-${record.id}`}
                style={{ marginRight: "5px" }}
              >
                <FaTrash />
              </button>
            )}

            {/* Edit Modal Body */}
            {/* Delete Modal Body */}

            <div
              className="modal fade"
              id={`deleteUserModal-${record.id}`}
              tabIndex={-1}
              style={{ display: "none" }}
              aria-hidden="true"
            >
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title">Delete User</h5>
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    />
                  </div>
                  <div className="modal-body pb-0">
                    <div className="mb-3 row ">
                      <div className="col-sm-10">
                        <p>Are you sure you want to delete?</p>
                      </div>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-danger"
                      onClick={() => handlerOnDelete(deleteModalData)}
                      data-bs-dismiss="modal"
                    >
                      Yes
                    </button>
                    <button
                      type="button"
                      className="btn btn-secondary"
                      data-bs-dismiss="modal"
                    >
                      No
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </>
        );
      },
    },
  ];

  const config = {
    page_size: 10,
    show_filter: false,
    show_length_menu: true,
    show_pagination: true,
    pagination: "advance",
    length_menu: [10, 50, 100],
    button: {
      // excel: true,
      // print: true,
      extra: true,
    },
  };

  const extraButtons = [
    {
      className: "bg-transparent border-0",

      title: "Search",
      children: [
        <Form.Group controlId="formBasicText">
          <Form.Control
            type={"search"}
            placeholder={"Search"}
            // value={value}
            onChange={(e) => handlerFilter(e.target.value)}
          />
        </Form.Group>,
      ],
      onClick: (event) => {
        // console.log(event);
      },
    },
  ];

  if (showRole) {
    firstThreeColumn.push({
      key: "role_id",
      text: "Role",
      className: "status",
      sortable: true,
      cell: (record) => {
        return <>{record?.role?.name}</>;
      },
    });
  }

  if (parseInt(role_id) === 10) {
    firstThreeColumn.push({
      key: "blood",
      text: "Blood Group",
      className: "status",
      sortable: true,
      cell: (record) => {
        return getBloodGroup(record?.user_detail?.blood_group);
      },
    });

    middleColumn.push({
      key: "last_blood_donate",
      text: "Last Donation Date",
      sortable: true,
      cell: (record) => {
        return <span>{record?.user_detail?.last_blood_donate}</span>;
      },
    });
  }
  // console.log('es', getBloodGroup(3))

  const columns = [...firstThreeColumn, ...middleColumn, ...lastColumnData];

  const handlerFilter = (value) => {
    if (searchHandler) {
      searchHandler(value);
    }
  };

  return (
    <>
      <div className="card">
        <div className="card-body">
          <div className="border p-3 rounded">
            <div className="card-box">
              <h6 className="mb-0 text-uppercase">Users List</h6>
              <div className="col">
                {/* Create User trigger modal Button */}
                {accessPerm(5, 1) && (
                  <button
                    type="button"
                    onClick={() =>
                      setModalState({
                        modalType: "create",
                        // modalData: {},
                      })
                    }
                    className="btn btn-primary"
                    // data-bs-toggle="modal"
                    // data-bs-target="#createUserModal"
                  >
                    <FaPlus />
                    Add New
                  </button>
                )}
              </div>
            </div>

            <hr />

            <div className="table-responsive">
              <ReactDatatable
                config={config}
                records={users}
                columns={columns}
                extraButtons={extraButtons}
              />
            </div>
          </div>
        </div>
      </div>

      {/* Create Modal Body */}

      {modalState.modalType === "create" && (
        <ModalLayout
          isOpen={modalState.modalType === "create"}
          onClose={() =>
            setModalState({
              modalType: "",
              modalData: {},
            })
          }
          size="xl"
          title="Create User"
        >
          <UsersFormContainer refresh={() => setRefresh((prev) => !prev)} />
        </ModalLayout>
      )}

      {modalState.modalType === "edit" && (
        <ModalLayout
          isOpen={modalState.modalType === "edit"}
          onClose={() =>
            setModalState({
              modalType: "",
              modalData: {},
            })
          }
          size="xl"
          title="Edit User"
        >
          <UsersFormContainer
            editData={modalState.modalData}
            refresh={() => setRefresh((prev) => !prev)}
          />
        </ModalLayout>
      )}

      {/* Delete Modal Body */}
      <PasswordChangeModal
        id={`#changePasswordModal`}
        user={passwordModalData}
      />
    </>
  );
};

export default UserTable;
