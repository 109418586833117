import { Form } from "react-bootstrap";
import ReactInput from "../../../components/InputList/ReactInput/ReactInput";
import { UserContext } from "../../../Context/UserContextAPI";
import { useContext } from "react";
import { useFormikContext } from "formik";
import ReactSelect from "../../../components/ReactSelect/ReactSelect";
import useFetch from "../../../hooks/useFetch";
import request from "../../../request/request";
const UsersForm = () => {
  const { allRoles: roles } = useContext(UserContext);
  const { values, setFieldValue } = useFormikContext();
  const { result: serviceCats } = useFetch(() => {
    return (
      values?.role_id &&
      request.getAllById("service-category/findbyrole", values?.role_id)
    );
  }, values?.role_id);

  const { result: serviceList } = useFetch(() => {
    return (
      values?.service_category_id &&
      request.getAllById(
        "service_category_list/service-list/service-cat",
        values?.service_category_id
      )
    );
  }, values?.service_category_id);

  console.log(values?.service_category_id);

  // console.log(serviceCats);
  // console.log("role_id", values?.role_id);
  // console.log("service_category_id", values?.gender_id);

  const genderList = [
    { id: null, name: "Select Gender" },
    { id: 1, name: "Male" },
    { id: 2, name: "Female" },
    { id: 3, name: "Others" },
  ];

  return (
    <div className="row d-flex align-items-start justify-content-between ">
      <div className="col-6 pe-4">
        <div className="row mb-3">
          <label className="col-sm-3 col-form-label">
            Select Role <span className="text-danger">*</span>
          </label>
          <div className="col-sm-9">
            <select
              className="form-select"
              name="role"
              aria-label="Default select example"
              required
              onChange={(e) => setFieldValue("role_id", +e.target.value)}
              value={values.role_id}
            >
              <option value={null}>Select Role</option>
              {roles?.map((role) => (
                <option key={role.id} value={parseInt(role?.id)}>
                  {role?.name}
                </option>
              ))}
            </select>
          </div>
        </div>

        <div className="row mb-3 d-flex align-items-center">
          <label className="col-sm-3 col-form-label">
            {values?.role_id === 11
              ? "Specifications"
              : values?.role_id === 12
              ? "Vehicle type - "
              : values?.role_id === 13
              ? "Service center type -"
              : values?.role_id === 14
              ? "Service Type -"
              : values?.role_id === 16
              ? "Shop Type -"
              : "Select Category"}
          </label>
          <div className="col-sm-9">
            <div className="col-12">
              <ReactSelect
                value={values?.service_category_id}
                isMulti={values?.role_id === 11}
                name="colors"
                data={serviceCats}
                valueName="id"
                labelName="name"
                className="basic-multi-select"
                classNamePrefix="select"
                onChange={(val) => {
                  setFieldValue("service_category_id", val);

                  // console.log(val);
                }}
              />
            </div>
          </div>
        </div>

        {values?.service_category_id === 13 && (
          <div className="row mb-3 d-flex align-items-center">
            <label className="col-sm-3 col-form-label">Service List</label>
            <div className="col-sm-9">
              <div className="col-12">
                <ReactSelect
                  value={values?.service_category_list_id}
                  name="colors"
                  data={serviceList}
                  valueName="id"
                  labelName="name"
                  className="basic-multi-select"
                  classNamePrefix="select"
                  onChange={(val) => {
                    setFieldValue("service_category_list_id", val);

                    // console.log(val);
                  }}
                />
              </div>
            </div>
          </div>
        )}

        <div className="row mb-3 d-flex align-items-center">
          <label className="col-sm-3 col-form-label">
            First Name / Company <span className="text-danger">*</span>
          </label>
          <div className="col-sm-9">
            <ReactInput
              type="text"
              name="f_name"
              className="form-control"
              required
            />
          </div>
        </div>
        <div className="row mb-3">
          <label className="col-sm-3 col-form-label">Last Name</label>
          <div className="col-sm-9">
            <ReactInput type="text" name="l_name" className="form-control" />
          </div>
        </div>
        <div className="row mb-3 d-flex align-items-center">
          <label className="col-sm-3 col-form-label">
            {values?.role_id === 14 ? "Drivers" : ""} Mobile Number
            <span className="text-danger">*</span>
          </label>
          <div className="col-sm-9">
            <ReactInput
              type="tel"
              // pattern='[0-9]{3}-[0-9]{4}-[0-9]{4}'
              name="mobile"
              className="form-control"
              required
            />
          </div>
        </div>

        {values?.form_type === "create" && (
          <div className="row mb-3">
            <label className="col-sm-3 col-form-label">
              Password <span className="text-danger">*</span>
            </label>
            <div className="col-sm-9">
              <ReactInput
                type="text"
                name="password"
                className="form-control"
                // required
              />
            </div>
          </div>
        )}
        <div className="row mb-3">
          <label className="col-sm-3 col-form-label">E-mail</label>
          <div className="col-sm-9">
            <ReactInput type="email" name="email" className="form-control" />
          </div>
        </div>
        <div className="row mb-3">
          <label className="col-sm-3 col-form-label">Date of Birth</label>
          <div className="col-sm-9">
            <ReactInput
              // id='date'
              type="date"
              data-date-format="YYYY MM DD"
              name="date_of_birth"
              className="form-control"
              placeholder="Date Picker..."
            />
          </div>
        </div>

        <div className="row mb-3 d-flex align-items-center">
          <label className="col-sm-3 col-form-label">Gender</label>
          <div className="col-sm-9">
            <ReactSelect
              value={values?.gender_id}
              name="colors"
              data={genderList}
              valueName="id"
              labelName="name"
              onChange={(val) => {
                setFieldValue("gender_id", val);
              }}
            />
          </div>
        </div>

        <div className="row mb-3 d-flex align-items-center">
          <label className="col-sm-3 col-form-label">Contact Person</label>
          <div className="col-sm-9">
            <ReactInput
              type="text"
              name="contact_person"
              className="form-control"
            />
          </div>
        </div>
        <div className="row mb-3">
          <label className="col-sm-3 col-form-label">NID Number</label>
          <div className="col-sm-9">
            <ReactInput type="number" name="nid" className="form-control" />
          </div>
        </div>

        {values?.role_id === 11 && (
          <div className="row mb-3">
            <label className="col-sm-3 col-form-label">BMDC No</label>
            <div className="col-sm-9">
              <ReactInput
                type="text"
                name="bmdc_license"
                className="form-control"
              />
            </div>
          </div>
        )}

        {(values?.role_id === 14 || values?.role_id === 12) && (
          <div className="row mb-3">
            <label className="col-sm-3 col-form-label">Driving License</label>
            <div className="col-sm-9">
              <ReactInput
                type="text"
                name="driving_license"
                className="form-control"
              />
            </div>
          </div>
        )}

        {values?.role_id === 14 && (
          <>
            <div className="row mb-3">
              <label className="col-sm-3 col-form-label">Company Name</label>
              <div className="col-sm-9">
                <ReactInput
                  type="text"
                  name="company_name"
                  className="form-control"
                />
              </div>
            </div>
            <div className="row mb-3">
              <label className="col-sm-3 col-form-label">Ambulance No</label>
              <div className="col-sm-9">
                <ReactInput
                  type="text"
                  name="ambulance_no"
                  className="form-control"
                />
              </div>
            </div>

            <div className="row mb-3">
              <label className="col-sm-3 col-form-label">
                Ambulance Registration No
              </label>
              <div className="col-sm-9">
                <ReactInput
                  type="text"
                  name="ambulance_reg_no"
                  className="form-control"
                />
              </div>
            </div>
            <div className="row mb-3">
              <label className="col-sm-3 col-form-label">Driver Name</label>
              <div className="col-sm-9">
                <ReactInput
                  type="text"
                  name="driver_name"
                  className="form-control"
                />
              </div>
            </div>
          </>
        )}

        {(values?.role_id === 13 ||
          values?.role_id === 17 ||
          values?.service_category_id === 39 ||
          values?.service_category_id === 40) && (
          <div className="row mb-3">
            <label className="col-sm-3 col-form-label">Trade License</label>
            <div className="col-sm-9">
              <ReactInput
                type="text"
                name="trade_license"
                className="form-control"
              />
            </div>
          </div>
        )}

        {(values?.role_id === 13 || values?.service_category_id === 39) && (
          <div className="row mb-3">
            <label className="col-sm-3 col-form-label">DGHS License</label>
            <div className="col-sm-9">
              <ReactInput
                type="text"
                name="dghs_license"
                className="form-control"
              />
            </div>
          </div>
        )}

        {values?.service_category_id === 9 && (
          <div className="row mb-3">
            <label className="col-sm-3 col-form-label">MotorBike No</label>
            <div className="col-sm-9">
              <ReactInput
                type="text"
                name="motorCycleNo"
                className="form-control"
              />
            </div>
          </div>
        )}
      </div>
      {/* second column */}

      <div className="col-6 ps-4">
        <div className="row mb-3">
          <label className="col-sm-3 col-form-label">Address_1</label>
          <div className="col-sm-9">
            <ReactInput
              type="textarea"
              name="address1"
              className="form-control w-100"
              rows="3"
              maxLength="200"
              as="textarea"
            />
          </div>
        </div>
        <div className="row mb-3">
          <label className="col-sm-3 col-form-label">Address_2</label>
          <div className="col-sm-9">
            <ReactInput
              type="textarea"
              name="address2"
              className="form-control w-100"
              rows="3"
              maxLength="200"
              as="textarea"
            />
          </div>
        </div>
        <div className="row mb-3">
          <label className="col-sm-3 col-form-label">Remarks</label>
          <div className="col-sm-9">
            <ReactInput
              type="textarea"
              name="remarks"
              className="form-control w-100"
              rows="3"
              maxLength="200"
              as="textarea"
            />
          </div>
        </div>
        <div className="row mb-3 ">
          <label className="col-sm-3 col-form-label">User Photo</label>
          <div className="col-sm-9">
            <ReactInput
              type="file"
              accept="image/*"
              name="image"
              className="form-control"
            />
          </div>
        </div>
        <div className="row mb-3 d-flex align-items-center">
          <label className="col-sm-3 col-form-label">Credit Limit (BDT)</label>
          <div className="col-sm-9">
            <ReactInput
              type="number"
              name="credit_limit"
              className="form-control"
            />
          </div>
        </div>
        <div className="row mb-3 d-flex align-items-center">
          <label className="col-sm-3 col-form-label">Commission Rate (%)</label>
          <div className="col-sm-9">
            <ReactInput
              type="number"
              name="commission_rate"
              className="form-control"
            />
          </div>
        </div>

        {values?.role_id === 10 && (
          <div className="row mb-3 d-flex align-items-center">
            <label className="col-sm-3 col-form-label">Blood Group</label>
            <div className="col-sm-9">
              <div className="col-12">
                <div className="form-group">
                  <select
                    className="form-control"
                    onChange={(e) =>
                      setFieldValue("blood_group", e.target.value)
                    }
                    value={values?.blood_group}
                  >
                    <option value="" disabled selected>
                      Blood Group
                    </option>

                    <option value={1}>A+</option>
                    <option value={2}>A-</option>
                    <option value={3}>B+</option>
                    <option value={4}>B-</option>
                    <option value={5}>O+</option>
                    <option value={6}>O-</option>
                    <option value={7}>AB+</option>
                    <option value={8}>AB-</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        )}

        {values?.role_id === 17 && (
          <>
            <div className="row mb-3 d-flex align-items-center">
              <label className="col-sm-3 col-form-label">
                Owner /Chairman /MD Name
              </label>
              <div className="col-sm-9">
                <ReactInput
                  type="text"
                  name="owner_name"
                  className="form-control"
                />
              </div>
            </div>

            <div className="row mb-3 d-flex align-items-center">
              <label className="col-sm-3 col-form-label">
                Representative Name
              </label>
              <div className="col-sm-9">
                <ReactInput
                  type="text"
                  name="responsible_person_name"
                  className="form-control"
                />
              </div>
            </div>
          </>
        )}

        <div className="row mb-3 d-flex align-items-center justify-content-end">
          <div className="col-sm-9">
            <div className="col-sm-12">
              <div className=" d-flex align-items-center  justify-content-end">
                <Form.Check
                  className="form-check-input mt-0 me-2"
                  type="checkbox"
                  checked={values?.status === 1}
                  name="status"
                  onChange={(e) =>
                    setFieldValue("status", e.target.checked ? 1 : 0)
                  }
                  id="flexCheckChecked"
                />
                <label className="form-check-label" htmlFor="flexCheckChecked">
                  Active
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UsersForm;
