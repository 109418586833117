import axios from "axios";
import FormLayout from "../../../components/Layout/FormLayout";
import UsersForm from "./UsersForm";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";
const UsersFormContainer = ({ editData, role_id, rolesData, refresh }) => {
  const { role_id: roleId } = useParams();
  console.log(roleId);
  const initialValues = {
    name: editData?.name || "",
    email: editData?.email || "",
    password: "",
    role_id: editData?.role_id || roleId || "",
    f_name: editData?.f_name || "",
    l_name: editData?.l_name || "",
    mobile: editData?.mobile || "",
    contact_person: editData?.contact_person || "",
    address1: editData?.address_1 || "",
    address2: editData?.address_2 || "",
    date_of_birth: editData?.date_of_birth || "",
    nid: editData?.nid || "",
    remarks: editData?.remarks || "",
    credit_limit: editData?.credit_limit || "",
    commission_rate: editData?.commission_rate || "",
    status: editData?.status === 0 ? 0 : 1,
    service_category_id: editData?.service_category_id || "",
    motorCycleNo: editData?.motorCycleNo || "",
    trade_license: editData?.user_detail?.trade_license || "",
    dghs_license: editData?.user_detail?.dghs_license || "",
    bmdc_license: editData?.user_detail?.bmdc_license || "",
    ambulance_no: editData?.user_detail?.ambulance_no || "",
    ambulance_reg_no: editData?.user_detail?.ambulance_reg_no || "",
    driver_name: editData?.user_detail?.driver_name || "",
    driving_license: editData?.user_detail?.driving_license || "",
    blood_group: editData?.user_detail?.blood_group || "",
    service_category_list_id: editData?.service_category_list_id || "",
    form_type: editData ? "edit" : "create",
    owner_name: editData?.user_detail?.owner_name || "",
    responsible_person_name:
      editData?.user_detail?.responsible_person_name || "",
    gender_id: editData?.user_detail?.gender_id || null,
  };

  const handlerOnCreateFormSubmit = async (formValue) => {
    try {
      let imagePath = null;

      if (formValue.image) {
        const formData = new FormData();
        formData.append("image", formValue.image);

        // Image Hosting Method(API)
        const res = await axios.post(
          `${process.env.REACT_APP_API_BASE_URL}/users/user/upload-image`,
          formData
        );

        imagePath = res.data.filename;
        console.log("image", imagePath);
      }

      const usersData = {
        f_name: formValue.f_name,
        l_name: formValue.l_name,
        email: formValue.email,
        date_of_birth: formValue.date_of_birth,
        address1: formValue.address1,
        address2: formValue.address2,
        password: formValue.password,
        mobile: formValue.mobile,
        contactPerson: formValue.contact_person,
        nid: formValue.nid,
        credit_limit: formValue.credit_limit,
        commission_rate: formValue.commission_rate,
        remarks: formValue.remarks,
        role_id: formValue.role_id,
        status: formValue.status,
        image: imagePath,
        service_category_id: Array.isArray(formValue.service_category_id)
          ? formValue.service_category_id?.map((item) => item.value)?.join(",")
          : formValue.service_category_id,
        blood_group: formValue.blood_group,
        trade_license: formValue.trade_license,
        dghs_license: formValue.dghs_license,
        bmdc_license: formValue.bmdc_license,
        ambulance_no: formValue.ambulance_no,
        ambulance_reg_no: formValue.ambulance_reg_no,
        driver_name: formValue.driver_name,
        driving_license: formValue.driving_license,
        service_category_list_id: formValue.service_category_list_id,
        gender_id: formValue.gender_id,
        owner_name: formValue.owner_name,
        responsible_person_name: formValue.responsible_person_name,
      };

      console.log("usersData", usersData);
      // return null;

      if (editData?.id) {
        await methodUpdateUser(usersData);
      } else {
        await methodCreateUser(usersData);
      }
      return null;
    } catch (error) {
      console.log(error);
      toast.error(error?.response?.data?.data?.error || "Something went wrong");
      // throw error;
    }
  };

  const methodCreateUser = async (usersData) => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/user/signup`,
        usersData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      console.log(res);

      if (res.status === 201) {
        toast.success("User Created successfully!!");
        refresh();
        // form.reset();
        return null;
      }
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  const methodUpdateUser = async (usersData) => {
    const res = await axios.put(
      `${process.env.REACT_APP_API_BASE_URL}/users/user/${editData?.id}`,
      usersData,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    // console.log(res);
    if (res.status === 200 && res?.data?.status === "success") {
      toast.success("User Updated successfully!!");
      refresh();
    }
  };

  return (
    <div style={{ overflowY: "scroll", height: "80vh" }}>
      <FormLayout
        initialValues={initialValues}
        apiHandler={handlerOnCreateFormSubmit}
      >
        <UsersForm />
      </FormLayout>
    </div>
  );
};

export default UsersFormContainer;
