import ReactDatatable from "@ashvin27/react-datatable";
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { UserContext } from "../../../Context/UserContextAPI";
import request from "../../../request/request";
import ModalLayout from "../../../components/Layout/ModalLayout";
import FormLayout from "../../../components/Layout/FormLayout";
import HealthProductsForm from "./HealthProductsForm";
import useFetch from "../../../hooks/useFetch";
import ReactTable from "../../../components/ReactDataTable/ReactTable";
import DeleteModal from "../../../components/Modal/DeleteModal";

const HealthProductsPage = () => {
  const { accessPerm } = useContext(UserContext);
  const [modalOpen, setModalOpen] = useState("");
  const [selectedItem, setSelectedItem] = useState(null);

  // fetch data

  const { result: productData, setRefresh } = useFetch(() =>
    request.getAll("health-products")
  );

  // fetch unit
  const { result: unitData } = useFetch(() =>
    request.getAll("health-products/unit/data")
  );

  // category list data fetch

  const { result: categoryData } = useFetch(() =>
    request.getAll("health-products/category/data")
  );

  //store User Data

  const { result: storeUserData } = useFetch(() =>
    request.getAll("users/store")
  );

  const confirmProducts = (id) => {
    request.updateOne("health-products/products/confirmed", id, "", () => {
      setRefresh((prev) => !prev);
    });
  };

  // console.log(data);
  const columns = [
    {
      key: "image",
      text: "Image",
      align: "left",
      sortable: true,
      width: 70,
      cell: (data) => {
        return (
          <img
            src={`${process.env.REACT_APP_UPLOAD_URL}/HealthProducts/${data.image}`}
            style={{ width: "70px", height: "70px", borderRadius: "5px" }}
            alt=""
          />
        );
      },
    },
    {
      key: "name",
      text: "Name",
      align: "left",
      sortable: true,
    },
    {
      key: "store_id",
      text: "Store",
      align: "left",
      sortable: true,
      cell: (data) => <span>{data?.store_info?.f_name}</span>,
    },
    {
      key: "uom",
      text: "Unit",
      align: "left",
      sortable: true,
      cell: (data) => {
        return <>{data.uom?.name}</>;
      },
    },
    {
      key: "cat",
      text: "Category",
      align: "left",
      sortable: true,
      cell: (data) => {
        return <>{data.health_products_category?.name}</>;
      },
    },
    {
      key: "regular_price",
      text: "Regular Price",
      align: "left",
      sortable: true,
    },
    {
      key: "discounted_price",
      text: "Discounted Price",
      align: "left",
      sortable: true,
    },
    {
      key: "points",
      text: "Points",
      align: "left",
      sortable: true,
    },
    {
      key: "info",
      text: "Info",
      align: "left",
      sortable: true,
    },

    {
      key: "status",
      text: "Status",
      className: "status",
      sortable: true,
      cell: (data) => (
        <div>
          <p>
            {" "}
            {data?.status === 1
              ? "Pending"
              : data?.status === 2
              ? "Active"
              : "Inactive"}{" "}
          </p>
        </div>
      ),
    },
    {
      key: "action",
      text: "Action",
      className: "action",
      width: 100,
      align: "left",
      sortable: false,
      cell: (data) => {
        return (
          <>
            <div className="col d-flex">
              {accessPerm(10, 2) && data?.status === 1 && (
                <button
                  type="button"
                  className="btn btn-success btn-sm me-2"
                  onClick={() => confirmProducts(data?.id)}
                >
                  Approve
                </button>
              )}

              {/* Button trigger modal */}
              {accessPerm(10, 2) &&
                (data?.status === 2 || data?.status === 3) && (
                  <button
                    type="button"
                    className="btn btn-primary btn-sm"
                    style={{ marginRight: "5px" }}
                    onClick={() => {
                      setModalOpen("update");
                      setSelectedItem(data);
                    }}
                  >
                    <i className="fa fa-edit"></i>
                  </button>
                )}
            </div>
            <div className="col">
              {/* Button trigger modal */}
              {accessPerm(10, 3) && (
                <button
                  type="button"
                  className="btn btn-danger btn-sm"
                  onClick={() => {
                    setModalOpen("delete");
                    setSelectedItem(data);
                  }}
                  style={{ marginRight: "5px" }}
                >
                  <i className="fa fa-trash"></i>
                </button>
              )}
            </div>
          </>
        );
      },
    },
  ];

  async function createHeathProduct(formValue) {
    // single image upload

    if (formValue?.image) {
      const formData = new FormData();
      formData.append("images", formValue?.image);
      const singleImageUp = await request.imageUpload(
        "health-products/upload",
        formData
      );
      formValue.image = singleImageUp[0]?.filename;
    }

    // multiple image upload

    if (
      formValue?.health_product_photos &&
      formValue?.health_product_photos.length > 0
    ) {
      const multipleFromData = new FormData();
      formValue?.health_product_photos.forEach((item, i) => {
        multipleFromData.append(`image-${i}`, item);
      });
      //
      const multiImageUp = await request.imageUpload(
        "health-products/upload",
        multipleFromData
      );
      console.log(multiImageUp);

      formValue.health_product_photos = multiImageUp;
    }

    // if (imageUpload) {
    //   formValue.image = imageUpload.filename;
    // } else {
    //   formValue.image = null;
    // }

    return request.create("health-products", formValue, () => {
      setRefresh((prev) => !prev);
      setModalOpen(false);
    });
  }

  async function updateHealthProducts(formValue) {
    // single image upload

    if (formValue?.image?.name) {
      const formData = new FormData();
      formData.append("images", formValue?.image);
      const singleImageUp = await request.imageUpload(
        "health-products/upload",
        formData
      );
      formValue.image = singleImageUp[0]?.filename;
    }

    // multiple image upload
    let product_images = [];
    if (
      formValue?.health_product_photos &&
      formValue?.health_product_photos.length > 0
    ) {
      // Use Promise.all to wait for all image uploads to finish
      await Promise.all(
        formValue?.health_product_photos.map(async (item) => {
          if (item?.name) {
            const photoData = new FormData();
            photoData.append("images", item);
            const productImage = await request.imageUpload(
              "health-products/upload",
              photoData
            );
            console.log(productImage);
            const allImages = productImage?.map((item) => {
              return {
                filename: item?.filename,
              };
            });
            product_images.push(...allImages);
          } else {
            product_images.push({ filename: item?.photo });
          }
        })
      );
    }
    console.log("product_images", product_images);

    formValue.health_product_photos = product_images;
    // return null;
    console.log("formValue", formValue);

    return request.updateOne(
      "health-products",
      selectedItem?.id,
      formValue,
      () => {
        setRefresh((prev) => !prev);
        setModalOpen(false);
      }
    );
  }

  const deleteData = async () => {
    return request.delete("health-products", selectedItem?.id, () => {
      setRefresh((prev) => !prev);
      setModalOpen(false);
    });
  };

  const modalClose = () => {
    setModalOpen("");
    setSelectedItem(null);
  };

  const initialValues = {
    store_id: "",
    name: "",
    image: "",
    unit_id: "",
    cat_id: "",
    reguller_price: "",
    discounted_price: "",
    points: "",
    info: "",
    status: 2,
    health_product_photos: [],
  };

  return (
    <>
      <div className="card">
        <div className="card-body">
          <div className="border p-3 rounded">
            <div className="card-box">
              <h6 className="mb-0 text-uppercase">Health Products List</h6>
              <div className="col">
                {/* Button trigger modal */}
                {accessPerm(10, 1) && (
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={() => setModalOpen("create")}
                  >
                    <i className="fa-solid fa-plus"></i> Add New
                  </button>
                )}

                {/* Add New Modal */}
              </div>
            </div>

            <hr />
            <ReactTable data={productData} columns={columns} />

            {(modalOpen === "create" || modalOpen === "update") && (
              <ModalLayout
                title={
                  modalOpen === "create"
                    ? "Create New Health Product"
                    : "Update Health Product"
                }
                onClose={modalClose}
                isOpen={modalOpen === "create" || modalOpen === "update"}
                size="xl"
              >
                <FormLayout
                  apiHandler={
                    modalOpen === "create"
                      ? createHeathProduct
                      : modalOpen === "update"
                      ? updateHealthProducts
                      : null
                  }
                  onClose={modalClose}
                  initialValues={
                    modalOpen === "update" ? selectedItem : initialValues
                  }
                >
                  <HealthProductsForm
                    unitData={unitData}
                    categoryData={categoryData}
                    storeData={storeUserData}
                  />
                </FormLayout>
              </ModalLayout>
            )}

            {/* delete modal  */}

            {modalOpen === "delete" && (
              <DeleteModal
                isOpen={modalOpen === "delete"}
                onClose={modalClose}
                handleDelete={deleteData}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default HealthProductsPage;
