import React from "react";
import { Image, Row } from "react-bootstrap";
import { useDropzone } from "react-dropzone";
import { useFormikContext } from "formik";
import { IoClose } from "react-icons/io5";

const DragAndDropForm = ({ remove }) => {
  const { values, setFieldValue } = useFormikContext();
  const { health_product_photos } = values;

  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
    onDrop: async (acceptedFiles) => {
      console.log(acceptedFiles);
      if (acceptedFiles) {
        await setFieldValue("health_product_photos", [
          ...(health_product_photos || []),
          ...acceptedFiles,
        ]);
      }
      // Add the selected files to the state
      // const fileNames = acceptedFiles.map((file) => file?.name);
      // setProductFirst((prevData) => ({
      //     ...prevData,
      //     gallery_photo: [...prevData.gallery_photo, ...fileNames],
      // }));
    },
  });

  return (
    <Row>
      <div className="form-group row mt-3">
        <div className="d-flex flex-column col-12 gap-4">
          <div
            {...getRootProps()}
            className="dropzone border col-12"
            style={{ height: "150px" }}
          >
            <input {...getInputProps()} />

            <div className="text-center cursor-pointer mt-3">
              <i className="bi bi-cloud-upload fs-1 " />
              <p className="fs-5 ">
                Drag & Drop some Images here, or Click to Select Files
              </p>
            </div>
          </div>
          <div className="image-preview d-flex flex-wrap gap-4 col-12">
            {values?.health_product_photos?.map((file, index) => (
              // console.log(file);
              <div
                key={index}
                className="preview-item position-relative img-cross"
                style={{ width: "170px" }}
              >
                <div className="text-end position-absolute cursor-pointer ">
                  <IoClose onClick={() => remove(index)} size={22} />
                </div>
                <Image
                  src={
                    // eslint-disable-next-line no-nested-ternary
                    file?.photo
                      ? `${process.env.REACT_APP_UPLOAD_URL}/HealthProducts/${file?.photo}`
                      : file?.path
                      ? URL.createObjectURL(file)
                      : ""
                  }
                  alt={`Preview ${file.name}`}
                  width={170}
                  height={150}
                />
                <p>{file.name}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </Row>
  );
};

export default DragAndDropForm;
