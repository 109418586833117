import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import ReactDatatable from "@ashvin27/react-datatable";
import formatDateTime from "../../utils/DateTimeFormate";
import { Form } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { UserContext } from "../../Context/UserContextAPI";
import toast from "react-hot-toast";
const SubscribedUserByPackageCat = () => {
  const [allSubsUser, setAllSubsUser] = useState([]);
  const [filteredSubsUser, setFilteredSubsUser] = useState([]);
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);

  const { currentUser } = useContext(UserContext);
  useEffect(() => {
    (async () => {
      try {
        const { data } = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/health-manager/getAllSubsByPackageCat/${id}`
        );
        // console.log("data", data);
        if (data.status === "OK") {
          setAllSubsUser(data?.data);
          setFilteredSubsUser(data?.data);
        } else {
          toast.error("Something went wrong");
        }
      } catch (error) {
        console.log(error);
      }
    })();
  }, [id, refresh]);

  const handlePaymentReceived = async (subsData) => {
    try {
      setLoading(true);
      const { data } = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/health-manager/payment-gateway/payment-received/${subsData?.id}`,
        {
          amount: subsData?.amount,
          received_by: currentUser?.id,
        }
      );
      console.log("data", data);
      if (data.status === "OK") {
        toast.success("Payment received successfully");
        setRefresh(!refresh);
      } else {
        toast.error("Payment received failed");
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  // console.log("l", filteredSubsUser);

  const columns = [
    {
      key: "image",
      text: "Image",
      className: "image",
      align: "left",
      sortable: true,
      cell: (record) => {
        return (
          <>
            {record?.user_info?.image ? (
              <img
                className=" img-fluid"
                style={{ width: "50px" }}
                src={`${process.env.REACT_APP_UPLOAD_URL}/users/${record?.user_info?.image}`}
                // src={`${process.env.REACT_APP_UPLOAD_URL}/users/${record?.image}`}
                alt="Profile Pic"
              />
            ) : (
              <img
                className=" img-fluid"
                style={{ width: "50px" }}
                src="./dummy.jpg"
                alt="Profile Pic"
              />
            )}
          </>
        );
      },
    },
    {
      key: "registration_no",
      text: "Registration No",
      className: "registration_no",
      align: "left",
      sortable: true,
      cell: (record) => {
        return <>{record?.user_info?.registration_no}</>;
      },
    },
    {
      key: "name",
      text: "Name / Company",
      className: "info",
      align: "left",
      sortable: true,
      cell: (record) => {
        return (
          <>
            {record?.user_info?.f_name} {record?.user_info?.l_name}
          </>
        );
      },
    },
    {
      key: "mobile",
      text: "Mobile",
      className: "mobile",
      sortable: true,
      cell: (record) => <span>{record?.user_info?.mobile}</span>,
    },
    {
      key: "email",
      text: "Email",
      className: "email",
      sortable: true,
      cell: (record) => {
        return <>{record?.user_info?.email}</>;
      },
    },
    {
      key: "package",
      text: "Package",
      className: "info",
      align: "center",
      // sortable: true,
      cell: (data) => <span>{data?.package?.name}</span>,
    },
    {
      key: "package_cat",
      text: "Package Category",
      className: "info",
      align: "center",
      // sortable: true,
      cell: (data) => <span>{data?.package?.packageCat?.name}</span>,
    },
    {
      key: "amount",
      text: "Amount",
      className: "info",
      align: "center",
      // sortable: true,
      cell: (data) => <span>{data?.amount || 0} BDT</span>,
    },
    {
      key: "purchase_date",
      text: "Subscription Date",
      className: "info",
      align: "center",
      sortable: true,
      cell: (data) => {
        return <span>{formatDateTime(data?.purchase_date)}</span>;
      },
    },
    {
      key: "expiry_date",
      text: "Expiry Date",
      className: "info",
      align: "center",
      sortable: true,
      cell: (data) => {
        return <span>{formatDateTime(data?.expiry_date)}</span>;
      },
    },
    {
      key: "received_by",
      text: "Payment Received By",
      className: "info",
      align: "center",
      sortable: true,
      cell: (data) => {
        return (
          <span>
            {data?.user_payment?.received_by_info?.f_name}
            {data?.user_payment?.received_by_info?.l_name}
          </span>
        );
      },
    },
    {
      key: "status",
      text: "Payment Status",
      className: "info",
      align: "center",
      sortable: true,
      cell: (data) => {
        return (
          <div>
            <button
              type="button"
              className={`btn btn-sm ${
                data?.payment_status === 1 ? "btn-warning" : "btn-success"
              }`}
            >
              {data?.payment_status === 1 ? "Unpaid" : "Paid"}
            </button>
            {data?.payment_status === 1 && (
              <button
                type="button"
                className="btn btn-sm btn-primary d-block mt-2"
                onClick={() => handlePaymentReceived(data)}
              >
                {loading ? "Loading..." : "Payment Received"}
              </button>
            )}
          </div>
        );
      },
    },
    // {
    //   key: "action",
    //   text: "Action",
    //   className: "info",
    //   align: "center",
    //   sortable: true,
    //   cell: (data) => {
    //     return (
    //       <div>
    //         <button type="button" className="btn btn-sm btn-primary">
    //           View
    //         </button>
    //       </div>
    //     );
    //   },
    // },
  ];
  const config = {
    page_size: 10,
    show_filter: false,
    show_length_menu: true,
    show_pagination: true,
    pagination: "advance",
    length_menu: [10, 50, 100],
    button: {
      extra: true,
    },
  };

  const extraButtons = [
    {
      className: "bg-transparent border-0",

      title: "Search",
      children: [
        <Form.Group controlId="formBasicText">
          <Form.Control
            type={"search"}
            placeholder={"Search"}
            // value={value}
            onChange={(e) => handlerFilter(e.target.value)}
          />
        </Form.Group>,
      ],
      onClick: (event) => {
        // console.log(event);
      },
    },
  ];

  // search/filter functions

  const handlerFilter = (value) => {
    setFilteredSubsUser(
      allSubsUser.filter((item) => {
        return (
          item?.user_info?.registration_no
            ?.toString()
            ?.includes(value.toLowerCase()) ||
          item?.user_info?.f_name.toLowerCase().includes(value.toLowerCase()) ||
          item?.user_info?.mobile?.toString()?.includes(value.toLowerCase())
        );
      })
    );
  };

  return (
    <div className="card">
      <div className="card-body">
        <div className="border p-3 rounded">
          <div className="card-box">
            <h6 className="mb-0 text-uppercase">Subscribed User List</h6>
            <div className="col">{/* Modal */}</div>
          </div>

          <hr />

          <div style={{ overflowY: "auto" }}>
            <ReactDatatable
              config={config}
              records={filteredSubsUser}
              columns={columns}
              extraButtons={extraButtons}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubscribedUserByPackageCat;
