import React, { useContext, useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { UserContext } from "../../Context/UserContextAPI";
import UserDetailsTab from "../../components/userDetailsTab/UserDetailsTab";
import axios from "axios";
import UserProfile from "../../components/userDetailsTab/UserProfile";
import useFetch from "../../hooks/useFetch";
import request from "../../request/request";
import RequestDetailsTab from "../../components/userDetailsTab/DonorDetailsTab/RequestDetailsTab";
import CardLayout from "../../components/Layout/CardLayout";

const ProfilePage = () => {
  const { currentUser } = useContext(UserContext);
  const [userDetails, setUserDetails] = useState({});
  const [subscriptionData, setSubscriptionData] = useState({});

  const { id } = useParams();

  const { result: userData } = useFetch(
    () => request.getOne("users/full-details", id),
    id
  );

  // console.log("resk", userData);

  // const {data: subscriptionData} = useFetchData(`health-manager/get-subs/${userDetails?.id}`);

  useEffect(() => {
    (async () => {
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/health-manager/get-subs/${id}`
        );

        if (res.data.data) {
          setSubscriptionData(res?.data?.data);
        }
      } catch (error) {
        console.log(error);
      }
    })();
  }, [id]);

  // console.log(location);
  // console.log(currentUser);

  useEffect(() => {
    (async () => {
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/users/details/user/${id}`
        );
        if (res.data.data) {
          setUserDetails(res.data?.data);
        }
        // console.log(res.data?.data)
      } catch (err) {
        console.log(err);
      }
    })();
  }, [id]);

  return (
    <>
      <CardLayout title="User Details">
        <div className="">
          {/*Tab sections */}
          <RequestDetailsTab
            userData={userData}
            userDetails={userData?.user_detail}
          />
        </div>
      </CardLayout>
    </>
  );
};

export default ProfilePage;
