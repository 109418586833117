import request from "../../../request/request";
import ReactTable from "../../../components/ReactDataTable/ReactTable";
import useFetch from "../../../hooks/useFetch";
import LoaderLayout from "../../../components/Loader/LoaderLayout";
import { useState } from "react";
import CardLayout from "../../../components/Layout/CardLayout";
import ModalLayout from "../../../components/Layout/ModalLayout";
import SliderFormContainer from "./SliderForm";
import { Image } from "react-bootstrap";
import EditButton from "../../../components/Button/EditButton";
import DeleteButton from "../../../components/Button/DeleteButton";
import DeleteModal from "../../../components/Modal/DeleteModal";

const SliderManagement = () => {
  const { result, isLoading, setRefresh } = useFetch(() =>
    request.getAll("slider-management")
  );

  const [modalState, setModalState] = useState({
    modalName: null,
    selectedId: null,
  });

  const columns = [
    {
      key: "title",
      text: "Title",
    },
    {
      key: "image",
      text: "Image",
      cell: (record) => {
        return (
          <Image
            src={`${process.env.REACT_APP_UPLOAD_URL}/slider/${record?.image}`}
            alt="Slider Image"
            style={{ width: "100px", height: "100px" }}
          />
        );
      },
    },
    {
      key: "description",
      text: "Description",
    },
    {
      key: "status",
      text: "Status",
      cell: (record) => {
        return <>{record?.status ? "Active" : "Inactive"}</>;
      },
    },
    {
      key: "action",
      text: "Action",
      width: "150px",
      cell: (record) => {
        return (
          <div>
            <EditButton
              onClick={() =>
                setModalState({ modalName: "edit", selectedId: record?.id })
              }
            />
            <DeleteButton
              onClick={() =>
                setModalState({ modalName: "delete", selectedId: record?.id })
              }
            />
          </div>
        );
      },
    },
  ];

  async function addSlider(data) {
    request.create("slider-management", data, () => {
      setModalState({ modalName: null, selectedId: null });
      setRefresh((prev) => !prev);
    });
  }

  async function editSlider(data) {
    request.updateOne("slider-management", modalState.selectedId, data, () => {
      setModalState({ modalName: null, selectedId: null });
      setRefresh((prev) => !prev);
    });
  }

  // delete slider
  async function deleteSlider() {
    request.delete("slider-management", modalState.selectedId, () => {
      setModalState({ modalName: null, selectedId: null });
      setRefresh((prev) => !prev);
    });
  }

  return (
    <LoaderLayout isPending={isLoading}>
      <CardLayout
        title="Slider Management"
        buttonTitle={"Add Slider"}
        addButtonHandler={() =>
          setModalState({ modalName: "add", selectedId: null })
        }
      >
        <ReactTable columns={columns} data={result} />
      </CardLayout>

      {modalState.modalName === "add" && (
        <ModalLayout
          title={"Add New"}
          isOpen={modalState.modalName === "add"}
          onClose={() => setModalState({ modalName: null, selectedId: null })}
          size="lg"
        >
          <SliderFormContainer submitHandler={addSlider} />
        </ModalLayout>
      )}

      {/* edit slider Modal */}
      {modalState.modalName === "edit" && (
        <ModalLayout
          title={"Edit Slider"}
          isOpen={modalState.modalName === "edit"}
          onClose={() => setModalState({ modalName: null, selectedId: null })}
          size="lg"
        >
          <SliderFormContainer
            editData={result?.find(
              (item) => item?.id === modalState?.selectedId
            )}
            submitHandler={editSlider}
          />
        </ModalLayout>
      )}

      {/* delete slider modal */}
      {modalState.modalName === "delete" && (
        <DeleteModal
          isOpen={modalState.modalName === "delete"}
          onClose={() => setModalState({ modalName: null, selectedId: null })}
          handleDelete={deleteSlider}
        />
      )}
    </LoaderLayout>
  );
};

export default SliderManagement;
