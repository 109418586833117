import { useParams } from "react-router-dom";
import useFetch from "../../../hooks/useFetch";
import request from "../../../request/request";
import LoaderLayout from "../../Loader/LoaderLayout";
import formatDateTime from "../../../utils/DateTimeFormate";
import ReactTable from "../../ReactDataTable/ReactTable";
import { Button } from "react-bootstrap";
import toast from "react-hot-toast";

const HealthPackageSubscribers = () => {
  const { id } = useParams();
  console.log("id", id);
  const {
    result: data,
    isLoading,
    setRefresh,
  } = useFetch(() =>
    request.getAllById("health-manager/service-center/subscribed-user", id)
  );
  console.log("data", data);
  const columns = [
    {
      key: "image",
      text: "Image",

      cell: (record) => {
        return (
          <>
            {record?.user_info?.image ? (
              <img
                className=" img-fluid"
                style={{ width: "50px" }}
                src={`${process.env.REACT_APP_UPLOAD_URL}/users/${record?.user_info?.image}`}
                // src={`${process.env.REACT_APP_UPLOAD_URL}/users/${record?.image}`}
                alt="Profile Pic"
              />
            ) : (
              <img
                className=" img-fluid"
                style={{ width: "50px" }}
                src="./dummy.jpg"
                alt="Profile Pic"
              />
            )}
          </>
        );
      },
    },
    {
      key: "registration_no",
      text: "Registration No",
      sortable: true,
      cell: (record) => {
        return <>{record?.user_info?.registration_no}</>;
      },
    },
    {
      key: "name",
      text: "Name",
      cell: (record) => {
        return (
          <>
            {record?.user_info?.f_name} {record?.user_info?.l_name}
          </>
        );
      },
    },
    {
      key: "mobile",
      text: "Mobile",
      cell: (record) => <span>{record?.user_info?.mobile}</span>,
    },
    {
      key: "email",
      text: "Email",
      cell: (record) => <span>{record?.user_info?.email}</span>,
    },
    {
      key: "package",
      text: "Package",
      cell: (data) => <span>{data?.package?.name}</span>,
    },
    {
      key: "package_price",
      text: "Package Price",
      cell: (data) => <span>{data?.amount} BDT</span>,
    },

    {
      key: "purchase_date",
      text: "Subscription Date",
      cell: (data) => {
        return <span>{formatDateTime(data?.purchase_date)}</span>;
      },
    },
    {
      key: "expiry_date",
      text: "Expiry Date",
      cell: (data) => {
        return <span>{formatDateTime(data?.expiry_date)}</span>;
      },
    },
    {
      key: "payment_method",
      text: "Payment Method",
      cell: (data) => <span>{data?.payment_method}</span>,
    },

    {
      key: "action",
      text: "Action",
      cell: (data) => {
        return (
          <div className="">
            <Button
              variant={data?.payment_status === 1 ? "danger" : "success"}
              size="sm"
            >
              {data?.payment_status === 1 ? "DUE" : "PAID"}
            </Button>
            {data?.payment_status === 1 && (
              <Button
                variant="primary"
                size="sm"
                className="mt-2"
                onClick={() => handlePaymentReceive(data)}
              >
                Payment Receive
              </Button>
            )}
          </div>
        );
      },
    },
  ];

  const handlePaymentReceive = (subscriptionData) => {
    const amount = subscriptionData?.package?.service_center_list.find(
      (item) => item.service_center_id === subscriptionData?.service_center_id
    )?.price;
    if (!amount) {
      toast.error("Amount not found");
      return;
    }
    request.create(
      `health-manager/payment-gateway/payment-received/${subscriptionData?.id}`,
      {
        amount: amount,
        received_by: id,
      },
      () => {
        toast.success("Payment received successfully");
        setRefresh((prev) => !prev);
      }
    );
  };

  return (
    <LoaderLayout isPending={isLoading}>
      <div className="flex flex-col gap-4">
        <h1 className="text-2xl font-bold">Health Package Subscribers</h1>
        <div style={{ overflowX: "auto" }}>
          <ReactTable columns={columns} data={data} loading={isLoading} />
        </div>
      </div>
    </LoaderLayout>
  );
};

export default HealthPackageSubscribers;
